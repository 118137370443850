import { createSelector } from 'reselect';
import { format, isSameMonth, isSameYear, differenceInCalendarDays, addDays } from 'date-fns';
import { formatDateWithoutOffset } from 'utils';
import { DateHelper } from 'pureUi/DatePicker';
import { bookingBuilderDomain } from '../../selectors';
import { SearchDomain, IDateRange } from './model';
import { IValueLabelPair } from '../../../../../interfaces';
import { HotelNameResponse, ICompany } from 'services/BackendApi';
import { escapeStringRegexp } from 'utils/string';
import { getTaFullName } from 'store/utils';
import { isInternalUser } from 'store/modules/auth/selectors';

export const searchSubdomainSelector = createSelector(bookingBuilderDomain, domain => domain.search);

export const offersQuerySelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['query'] => domain.query
);

export const initializedSelector = createSelector(searchSubdomainSelector, domain => domain.initialized);

export const activeLodingIndexSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['activeLodgingIndex'] => domain.activeLodgingIndex
);

export const lodgingSelector = createSelector(
  offersQuerySelector,
  (query): SearchDomain['query']['lodgings'] => query.lodgings
);

export const totalGuestCountSelector = createSelector(lodgingSelector, (lodgings): number =>
  lodgings.reduce((acc, next) => acc + next.numberOfAdults + next.agesOfAllChildren!.length, 0)
);

export const showLodgingControlsSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['showLodgingControls'] => domain.showLodgingControls
);

export const showNameSearchResultsSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['showNameSearchResults'] => domain.showNameSearchResults
);

export const dateRangeSelector = createSelector(
  offersQuerySelector,
  (query): IDateRange => {
    // @see https://pureescapes.atlassian.net/browse/OWA-1031
    const end = addDays(new Date(query.endDate), 1);
    return { start: query.startDate, end: formatDateWithoutOffset(end) };
  }
);

export const totalStayNightsSelector = createSelector(dateRangeSelector, (dateRange): number => {
  if (!dateRange.end) {
    return 0;
  }
  return differenceInCalendarDays(new Date(dateRange.end), new Date(dateRange.start));
});

export const totalStayDaysSelector = createSelector(totalStayNightsSelector, (days): number => {
  return days + 1;
});

export const isRepeatGuestSelector = createSelector(lodgingSelector, (lodging): boolean => lodging[0].repeatCustomer);

export const selectedDatesSelector = createSelector(
  dateRangeSelector,
  totalStayDaysSelector,
  (dateRange, totalStayDays): string[] => {
    const firstTimestamp = new Date(dateRange.start).getTime();
    return DateHelper.generateDatesFrom(firstTimestamp, totalStayDays, 'en-US').map(d => d.dateString);
  }
);

export const dateRangeDisplayStringSelector = createSelector(dateRangeSelector, (dateRange): string => {
  if (!dateRange.start || !dateRange.end) {
    return 'Select date range';
  }

  const startDate = new Date(dateRange.start);
  const endDate = new Date(dateRange.end);

  const startDay = format(startDate, 'd');
  const endDay = format(endDate, 'd');
  const startMonth = format(startDate, 'LLL');
  const endMonth = format(endDate, 'LLL');
  const startYear = format(startDate, 'yyyy');
  const endYear = format(endDate, 'yyyy');
  const inSameMonth = isSameMonth(startDate, endDate);
  const inSameYear = isSameYear(startDate, endDate);

  if (!inSameMonth && inSameYear) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
  }

  if (!inSameMonth && !inSameYear) {
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }

  return `${startDay} - ${endDay} ${startMonth} ${endYear}`;
});

export const datePickerCurrentDateSelector = createSelector(
  searchSubdomainSelector,
  domain => domain.datePickerCurrentDate
);

export const dateSelectionInProgressSelector = createSelector(
  searchSubdomainSelector,
  domain => domain.dateSelectionInProgress
);

export const showDatePickerSelector = createSelector(searchSubdomainSelector, domain => domain.showDatePicker);

export const isFetchingNameSearchSelector = createSelector(
  searchSubdomainSelector,
  domain => domain.isFetchingNameSearch
);

export const nameSearchRawResultsSelector = createSelector(searchSubdomainSelector, domain => domain.nameSearchResults);

export const nameSearchResultsSelector = createSelector(searchSubdomainSelector, (domain): string[][] => {
  if (!domain.nameSearchResults) {
    return [];
  }
  const countries = domain.nameSearchResults.countries.map(v => v.name);
  const hotels = domain.nameSearchResults.hotels.map(v => v.name);
  return [['All countries and resorts'], countries, hotels];
});

export const hotelsSearchResultsSelector = createSelector(searchSubdomainSelector, (domain): HotelNameResponse[] => {
  if (!domain.nameSearchResults) {
    return [];
  }
  return domain.nameSearchResults.hotels;
});

export const hotelSelector = createSelector(offersQuerySelector, hotelsSearchResultsSelector, (query, hotels) => {
  const name = (query.name || '').trim().toLowerCase();
  return hotels.find(x => x.name.toLowerCase() === name);
});

export const companyRequestErrorSelector = createSelector(
  searchSubdomainSelector,
  domain => domain.companyRequestError
);

export const taRequestErrorSelector = createSelector(searchSubdomainSelector, domain => domain.taRequestError);

export const selectedTaSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['selectedTa'] | null => domain.selectedTa
);

export const showTaDropdownSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['showTaDropdown'] => domain.showTaDropdown
);

export const taNameSearchSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['taNameSearch'] => domain.taNameSearch
);

export const isFetchingTaSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['isFetchingTA'] => domain.isFetchingTA
);

export const selectedCompanySelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['selectedCompany'] => domain.selectedCompany
);

export const selectedCompanyCountryCodeSelector = createSelector(
  searchSubdomainSelector,
  (domain): string => domain.selectedCompany?.countryCode || ''
);

export const showCompanyDropdownSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['showCompanyDropdown'] => domain.showCompanyDropdown
);

export const companyNameSearchSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['companyNameSearch'] => domain.companyNameSearch
);

export const isFetchingCompaniesSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['isFetchingCompanies'] => domain.isFetchingCompanies
);

export const companiesSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['companies'] => domain.companies
);

export const companySelectOptionsSelector = createSelector(
  companiesSelector,
  isFetchingCompaniesSelector,
  companyRequestErrorSelector,
  (companies: ICompany[] | undefined, requestPending: boolean, error: string | undefined): IValueLabelPair[] => {
    if (requestPending) {
      return [{ value: '', label: 'Loading companies', disabled: true }];
    }

    if (error) {
      return [{ value: '', label: 'Error loading companies', disabled: true }];
    }

    if (!companies || companies.length === 0) {
      return [{ value: '', label: 'No Companies', disabled: true }];
    }

    const initialOption = { value: '', label: 'All Companies' };
    const options = companies.map(c => ({
      value: c.uuid,
      label: c.name,
    }));

    return [initialOption, ...options];
  }
);

export const selectedCompanyAgents = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['selectedCompanyAgents'] => domain.selectedCompanyAgents
);

export const selectedCompanyAgentsNames = createSelector(searchSubdomainSelector, (domain): string[] => {
  if (!domain.selectedCompanyAgents) {
    return [];
  }
  const escaped = escapeStringRegexp(domain.taNameSearch.toLocaleLowerCase());
  return domain.selectedCompanyAgents
    .map(getTaFullName)
    .filter(name => name.toLocaleLowerCase().search(escaped) !== -1);
});

export const companyNamesSelector = createSelector(searchSubdomainSelector, (domain): string[] => {
  if (!domain.companies) {
    return [];
  }
  const escaped = escapeStringRegexp(domain.companyNameSearch.toLocaleLowerCase());
  return domain.companies.map(c => c.name).filter(name => name.toLocaleLowerCase().search(escaped) !== -1);
});

export const canSearchSelector = createSelector(
  isInternalUser,
  lodgingSelector,
  dateRangeSelector,
  selectedCompanySelector,
  selectedTaSelector,
  isFetchingNameSearchSelector,
  (isUserSr, lodgings, dateRange, company, ta, isFetchingNameSearch): boolean =>
    [
      dateRange.start,
      dateRange.end,
      lodgings.every(lg => lg.numberOfAdults > 0 || (lg.agesOfAllChildren && lg.agesOfAllChildren.length > 0)),
      isUserSr ? company && ta : true,
      !isFetchingNameSearch,
    ].every(x => x)
);

export const travelCompanyMembershipSelector = createSelector(
  searchSubdomainSelector,
  (domain): SearchDomain['selectedTravelCompanyMembership'] => domain.selectedTravelCompanyMembership
);
